import { render, staticRenderFns } from "./MassUserCreate.vue?vue&type=template&id=2d4e9282&scoped=true"
import script from "./MassUserCreate.vue?vue&type=script&lang=js"
export * from "./MassUserCreate.vue?vue&type=script&lang=js"
import style0 from "./MassUserCreate.vue?vue&type=style&index=0&id=2d4e9282&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d4e9282",
  null
  
)

export default component.exports